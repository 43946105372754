<template>
  <Transition>
    <div
      v-if="show"
      class="max-w-[480px] fixed z-10 h-full bg-white flex justify-center items-center"
    >
      <img src="@/assets/images/shihonet_ogp.png" class="w-full" />
    </div>
  </Transition>

    <BaseModal>
      <img src="@/assets/images/20250202_happy_birthday.png" class="max-w-rounded-3xl shadow-2xl" />
    </BaseModal>

  <FadeInOnScroll>
    <img src="@/assets/images/top_image.png" />
  </FadeInOnScroll>

  <FadeInOnScroll>
    <div class="mt-40 mx-6 flex flex-col items-center">
      <ChangeFontToCaveat :text="'Tap here!!'" class="mx-auto" />
      ↓
      <RouterLink to="/graduation/messages" class="mx-auto">
        <ChangeFontToCaveat
          class="text-site-color text-3xl underline cursor-pointer"
          :text="'Thanks for becoming idol.'"
        />
      </RouterLink>
    </div>
  </FadeInOnScroll>

  <TopProfiles class="mt-32" />
<!--  <TopSchedules />-->
  <TopBlogs />
</template>

<script setup lang="ts">
import {
  ChangeFontToCaveat,
  FadeInOnScroll,
  BaseModal,
} from "@/views/components/common";
import { TopProfiles, TopBlogs } from "@/views/components/top";
import { onMounted, ref } from "vue";

const show = ref(true);

onMounted(() => {
  show.value = false;
});
</script>
