<template>
  <div class="w-full bg-site-color px-8 pt-10 pb-10 text-white">
    <ul class="text-[20px] font-normal">
      <li class="pb-4">
        <RouterLink to="/profiles" class="hover:opacity-80"
          >Profiles</RouterLink
        >
      </li>
      <li class="pb-4">
        <RouterLink to="/blogs" class="hover:opacity-80">Blogs</RouterLink>
      </li>
      <li class="pb-4">
        <RouterLink to="/histories" class="hover:opacity-80"
          >Histories</RouterLink
        >
      </li>
      <li class="pb-4">
        <RouterLink to="/about" class="hover:opacity-80"
          >About "#shihonet"</RouterLink
        >
      </li>
      <li class="pb-4">
        <RouterLink to="/thanks_post" class="hover:opacity-80"
          >Thanks Generator</RouterLink
        >
      </li>
    </ul>
    <div class="mt-10 text-[16px]">
      <p>〜他ファンサイト〜</p>
      <ul>
        <li class="mt-1">
          -
          <a
            href="https://nibuparu2286.wixsite.com/mysite"
            target="_blank"
            class="hover:opacity-80"
            >#nibupage（丹生明里さん/日向坂46OG）</a
          >
        </li>
        <li class="mt-1">
          -
          <a
            href="https://kyonkosite95.wixsite.com/kyonkosite"
            target="_blank"
            class="hover:opacity-80"
            >#kyonkosite（齊藤京子さん/日向坂46OG）</a
          >
        </li>
      </ul>
    </div>
    <div class="mt-16 flex items-center justify-center">
      <a href="https://x.com/shihonet0202" target="_blank">
        <i class="pi pi-twitter" style="color: black; font-size: 32px"></i>
      </a>
    </div>
    <div class="mt-16 text-[8px] text-center">
      <p>© 2024 #shihonet, developing by @shihonet.</p>
      <p>
        このサイトは、Seed &
        Flower合同会社その他各種権利者から許諾等を得ているものではありません。
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>